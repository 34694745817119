import './polyfills';

import { serializeError } from '@daxo/util/error';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

import { dev } from '$app/environment';

export const handleErrorMain: HandleClientError = ({ error, event }) => {
  console.error(error);

  const { route } = event;

  const errorId = crypto.randomUUID();

  return {
    message: 'Internal Server Error',
    errorId,
    detail: {
      error: dev ? serializeError(error) : undefined,
      routeId: route.id,
    },
  };
};

export const handleError: HandleClientError = Sentry.handleErrorWithSentry(handleErrorMain);
